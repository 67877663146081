import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Button, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Info } from '@mui/icons-material';
import { configManager } from 'src/app/common/utils';
import { get } from 'lodash';
import { DialogueConfigItemType } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  exportedCount: number;
  calledCount: number;
  flowId: number;
  showTooltip?: boolean;
};

export const AiTalkbotItemComponent: React.FC<ComponentProps> = memo(
  ({ exportedCount, calledCount, flowId, showTooltip }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();

    const dialogConfigs = get(configManager.getConfig('sales-common'), 'Sales.talkbot.dialogues', []);

    const config = useMemo(() => {
      return [
        { label: t('campaign.talkbot.exported'), value: exportedCount },
        { label: t('campaign.talkbot.called'), value: calledCount },
      ];
    }, [exportedCount, calledCount]);

    return (
      <Stack>
        <Divider sx={{ marginBottom: 3 }} />
        <Typography fontSize={16} fontWeight={600} marginBottom={3}>
          {t('title.talkbot')}
        </Typography>
        <Stack flexDirection="row" alignItems="center" marginBottom={3}>
          <Typography fontSize={14} fontWeight={500} sx={{ color: '#666' }}>
            {t('campaign.talkbot.tips', {
              name: t(
                `talkbot.dialogue.key.${dialogConfigs?.find(
                  (item: DialogueConfigItemType) => Number(item.flowId) === Number(flowId),
                )?.dialogueKey}`,
              ),
            })}
          </Typography>
          {showTooltip && (
            <Tooltip arrow title={t('campaign.talkbot.tooltip')}>
              <Info sx={{ color: '#CCCCCC', marginLeft: 0.5, width: 16, height: 16 }} />
            </Tooltip>
          )}
        </Stack>
        <Grid container spacing={2.5}>
          {config?.map(({ label, value }) => {
            return (
              <Grid item key={label}>
                <Stack
                  sx={{
                    padding: 2,
                    justifyContent: 'space-between',
                    width: 100,
                    height: 90,
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '12px',
                    wordWrap: 'break-word',
                  }}
                >
                  <Typography fontSize={12} fontWeight={500}>
                    {label}
                  </Typography>
                  <Typography fontSize={18} fontWeight={700}>
                    {value}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
        <Divider sx={{ marginBottom: 3, marginTop: 3 }} />
      </Stack>
    );
  },
);
